require('../datatable.js')

$(document).on("turbolinks:load", function() {
  $('#licenses__search-wrap').text("");
  $('#licenses__pagination-wrap').text("");
  $('.licenses-container').datatable({
    search: true,
    searchSelector: '#licenses__search-wrap',
    namePlural: $('.licenses-container').attr("plural"),
    nameSingular: $('.licenses-container').attr("singular"),
    searchPlaceholder: "Search",
    pagingSelector: '#licenses__pagination-wrap',
    elementSelector: 'tr.license',
    rootnode: '.licenses-wrap',
    isTable: false,
    elementsPerPage: 10
  });
});
